export const environment = {
  firebase: {
    apiKey: "AIzaSyCVrBckBX09M8r-YiK1sH9vCGbjplJwEGA",
    authDomain: "stage-carrot-sso.firebaseapp.com",
    projectId: "stage-carrot-sso",
    storageBucket: "stage-carrot-sso.appspot.com",
    messagingSenderId: "1037741135733",
    appId: "1:1037741135733:web:75dca639194c6c612a253c",
    measurementId: "G-Q19MG5K6CE"
  },
  production: true,
  name: "STAGE",
  useEmulators: false,
  apiBaseUrl: "",
  clientId: "R2yxwMCW2qtJsX03sK0x"
};
